import { Post } from "src/http/httpClient";
import {
  ILoginRequest,
  ILoginResponse,
  ISignUpRequest,
  ISignUpResponse,
} from "./auth.contracts";

export const SignUp = async (
  request: ISignUpRequest
): Promise<ISignUpResponse> => {
  return Post("signup", request);
};

export const Login = async (
  request: ILoginRequest
): Promise<ILoginResponse> => {
  return Post("login", request);
};
