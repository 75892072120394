// src/redux/authSlice.ts

import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ILoginRequest,
  ILoginResponse,
  ISignUpRequest,
  ISignUpResponse,
} from "src/services/auth/auth.contracts";
import { SignUp, Login } from "src/services/auth/auth.services";

interface AuthState {
  isAuthenticated: boolean;
  userId?: string;
  firstName: string;
}

const initialState: AuthState = {
  isAuthenticated: false,
  userId: "",
  firstName: "",
};

export const signup = createAsyncThunk(
  "auth/signup",
  async (request: ISignUpRequest) => {
    const response: ISignUpResponse = await SignUp(request);
    return response;
  }
);

export const login = createAsyncThunk(
  "auth/login",
  async (request: ILoginRequest) => {
    const response: ILoginResponse = await Login(request);
    return response;
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuth: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signup.fulfilled, (state, action) => {
      // handle signup response
    });
    builder.addCase(login.fulfilled, (state, action) => {
      // handle login response
    });
  },
});

export const { setAuth } = authSlice.actions;
export const selectAuth = (state: { auth: AuthState }) => state.auth;
export const selectIsAuthenticated = (state: { auth: AuthState }) =>
  state.auth.isAuthenticated;
export const selectUserId = (state: { auth: AuthState }) => state.auth.userId;
export const selectFirstName = (state: { auth: AuthState }) =>
  state.auth.firstName;

export default authSlice.reducer;
