import React, { FC, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PrivateRoute from "./privateRoute";
import PublicRoute from "./publicRoute";

// Lazy load components
const Home = React.lazy(() => import("src/pages/homePage/HomePage"));
const PlayerHome = React.lazy(() => import("src/pages/playerHome/PlayerHome"));
const PlayerWaitingPage = React.lazy(
  () => import("src/pages/playerWaitingPage/PlayerWaitingPage")
);
const TemplateDetails = React.lazy(
  () => import("src/pages/templateDetails/TemplateDetails")
);
const PlayerQuizPage = React.lazy(
  () => import("src/pages/playerQuizPage/PlayerQuizPage")
);
const HostGameMode = React.lazy(
  () => import("src/pages/hostGameMode/HostGameMode")
);
const GameSettingsPage = React.lazy(
  () => import("src/pages/gameSettingsPage/GameSettingsPage")
);
const QuizResult = React.lazy(
  () => import("src/pages/quizResultDetails/QuizResult")
);
const PlayerLeaderboard = React.lazy(
  () => import("src/pages/playerLeaderboard/PlayerLeaderboard")
);
const UpcomingGames = React.lazy(
  () => import("src/pages/upcomingGames/UpcomingGames")
);
const MyLibrary = React.lazy(() => import("src/pages/myLibrary/MyLibrary"));

const PasswordReset = React.lazy(
  () => import("src/pages/passwordReset/PasswordReset")
);
const PlayerQuizSummary = React.lazy(
  () => import("src/components/playerQuizSummary/PlayerQuizSummary")
);
const PlayerQuizResult = React.lazy(
  () => import("src/pages/quizResultDetails/PlayerQuizResult")
);
const ShareLeaderboard = React.lazy(
  () => import("src/pages/share/ShareLeaderboard")
);
const Payment = React.lazy(() => import("src/components/payment/Payment"));

const DemoQuiz = React.lazy(() => import("src/pages/demo/DemoQuiz"));

const FAQ = React.lazy(() => import("src/components/faqUi/faqUi"));

const QuizCreation = React.lazy(
  () => import("src/pages/createQuiz/QuizCreation")
);

const Contact = React.lazy(() => import("src/pages/contact/Contact"));

const AdminViewContact = React.lazy(
  () => import("../pages/contact/AdminViewContact")
);

const MyGame = React.lazy(() => import("src/pages/myGame/MyGame"));

const PageNotFound = React.lazy(() => import("src/routeConfig/PageNotFound"));

const JoinPlayer = React.lazy(() => import("src/pages/joinCode/JoinPlayer"));

const AchievePage = React.lazy(
  () => import("src/pages/achievePage/AchievePage")
);

const Blog = React.lazy(() => import("../pages/blog/Blogs"));
const ContentBlog = React.lazy(
  () => import("../components/blogContenet/ContentBlog")
);

type RouteItem = {
  name: string;
  path: string;
  component: React.ComponentType;
  isPrivate: boolean;
};
const routesArray: RouteItem[] = [
  { name: "Home", path: "/", component: Home, isPrivate: false },
];
const Routers: FC = () => {
  return (
    <Router>
      <Suspense fallback={<div></div>}>
        <Routes>
          {routesArray.map((route, index) => {
            return route.isPrivate ? (
              <Route
                key={index}
                path={route.path}
                element={<PrivateRoute component={route.component} />}
              />
            ) : (
              <Route
                key={index}
                path={route.path}
                element={<PublicRoute component={route.component} />}
              />
            );
          })}
          <Route path="/" element={<Home />} />
          <Route
            path="/host/quiz-detail/:QuizId"
            element={<TemplateDetails />}
          />
          <Route
            path="host/quiz/game-settings/:gameId"
            element={<GameSettingsPage />}
          />
          <Route path="host/gamemode/:gameid" element={<HostGameMode />} />
          <Route path="host/result/:slug" element={<QuizResult />} />
          <Route path="player/result/:slug" element={<PlayerQuizResult />} />
          <Route
            path="demo/player/result/:slug"
            element={<PlayerQuizResult />}
          />
          <Route path="player/:slug" element={<PlayerHome />} />
          <Route path="/:quizName" element={<PlayerHome />} />
          <Route path="/join-player" element={<JoinPlayer />} />
          <Route path="player/waiting" element={<PlayerWaitingPage />} />
          <Route path="player/quiz/:slug" element={<PlayerQuizPage />} />
          <Route
            path="player-leaderboard/:slug"
            element={<PlayerLeaderboard />}
          />
          <Route path="/Achieve-Page" element={<AchievePage />} />
          <Route path="/host/upcoming-games" element={<UpcomingGames />} />
          <Route path="host/my-library" element={<MyLibrary />} />
          <Route path="host/my-game" element={<MyGame />} />
          <Route path="player-quiz-summary" element={<PlayerQuizSummary />} />
          <Route path="demo/:slug" element={<DemoQuiz />} />
          <Route path="Payment/:status" element={<Payment />} />
          <Route
            path="host/password-reset/:token/:email"
            element={<PasswordReset />}
          />
          <Route path="leaderboard/:slug" element={<ShareLeaderboard />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/create-quiz" element={<QuizCreation />} />
          <Route path="get-contact-details" element={<AdminViewContact />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blogs" element={<Blog />} />
          <Route path="/blogs/:id/:title" element={<ContentBlog />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Suspense>
    </Router>
  );
};
export default Routers;
