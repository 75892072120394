import React, { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "src/redux/hook";
import { selectIsAuthenticated } from "src/redux/slices/authSlice";

type PrivateRouteProps = {
  component: React.ComponentType;
};

const PrivateRoute: FC<PrivateRouteProps> = ({ component: Component }) => {
  const navigate = useNavigate();
  const isAuthenticated = useAppSelector(selectIsAuthenticated); // ...your authentication logic here

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated, navigate]);

  if (!isAuthenticated) {
    return null;
  }

  return <Component />;
};

export default PrivateRoute;
